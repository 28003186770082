















































import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import isDomain from 'is-domain';
import { getDomain, getSubdomain, parse } from 'tldjs';
import { MsModal } from '@/components';
import { eventBus } from '@/libs/eventBus';
import { IOptIn } from '@/typings';

@Component({ components: { MsModal } })
export default class HomePage extends Vue {
  public eventBus = eventBus;
  public urlModel: string = '';
  public selectedOptInIndex: number = 0;
  public optIns: IOptIn[] = [];

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public get isDomainValid(): boolean {
    return (
      this.urlModel &&
      parse(this.urlModel).isValid &&
      isDomain(this.cleanDomain(this.urlModel))
    );
  }

  public get enableButton(): boolean {
    return (
      this.optIns.every((optIn) => optIn.checked === true) && this.isDomainValid
    );
  }

  public created(): void {
    this.urlModel = this.$store.state.domain;
    this.optIns = this.$store.state.widgetConfig.optIns.map((optIn: IOptIn) => {
      return { ...optIn, checked: false };
    });
  }

  public handleOptIn(index: number): void {
    this.optIns[index].checked = !this.optIns[index].checked;
  }

  public cleanDomain(dirtyDomain: string) {
    const subdomain = getSubdomain(dirtyDomain);
    const domain = getDomain(dirtyDomain);
    let output = domain;
    if (subdomain) {
      output = `${subdomain}.${domain}`;
    }
    return output;
  }

  public setDomain(): void {
    if (
      parse(this.urlModel).isValid &&
      isDomain(this.cleanDomain(this.urlModel))
    ) {
      this.$store.commit('SET_DOMAIN', this.cleanDomain(this.urlModel));
    }
  }

  public handleNextStep(): void {
    if (!this.enableButton) {
      return;
    }
    this.$router.push('/user-data');
  }
}
