






































































import { Component, Vue } from 'vue-property-decorator';
import { upperFirst } from 'lodash';
// @ts-ignore
import isEmail from 'is-email';
import { ApiClient, ICreateProspectDTO } from '@/libs/ApiClient';
import { MsLoading } from '@/components';
import { catchError, filter, map, mergeMap, retry, takeUntil, tap } from 'rxjs/operators';
import { of, Subject, timer } from 'rxjs';

@Component({
  components: {
    MsLoading,
  },
})
export default class UserDataPage extends Vue {
  public firstNameModel: string = '';
  public lastNameModel: string = '';
  public emailModel: string = '';
  public phoneModel: string = '';

  public upperFirst = upperFirst;

  private stopPolling = new Subject();

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public get enableButton(): boolean {
    return (
      this.firstNameModel &&
      this.lastNameModel &&
      this.emailModel &&
      isEmail(this.emailModel)
    );
  }

  public get emailPlaceholder(): string {
    return `${this.$store.state.firstName || this.$t('placeholder.first_name')
      }.${this.$store.state.lastName || this.$t('placeholder.last_name')}@${this.$store.state.domain
      }`.toLowerCase();
  }

  public created() {
    if (!this.$store.state.domain) {
      this.$router.push('/');
    }
    this.firstNameModel = this.$store.state.firstName;
    this.lastNameModel = this.$store.state.lastName;
    this.emailModel = this.$store.state.email;

    this.$nextTick(() => {
      if (this.$refs.firstNameInputElement) {
        // @ts-ignore
        this.$refs.firstNameInputElement.focus();
      }
    });
  }

  public setFirstName() {
    this.$store.commit('SET_FIRSTNAME', this.firstNameModel);
  }

  public setLastName() {
    this.$store.commit('SET_LASTNAME', this.lastNameModel);
  }

  public setEmail() {
    this.$store.commit('SET_EMAIL', this.emailModel);
  }

  public setPhone() {
    this.$store.commit('SET_PHONE', this.phoneModel);
  }

  public handleStartScan() {
    this.$store.commit('TOGGLE_LOADING');

    const apiClient = new ApiClient({ apiKey: this.$store.state.apiKey });

    const prospectData: ICreateProspectDTO = {
      firstName: this.$store.state.firstName,
      lastName: this.$store.state.lastName,
      email: this.$store.state.email,
      domain: this.$store.state.domain,
    };

    if (this.$store.state.phone) {
      prospectData.phone = this.$store.state.phone;
    }

    // creates a prospect
    apiClient.createProspect(prospectData).pipe(
      // gets the created prospect
      map((res) => res.data),
      // sets the id of the prospect
      tap((prospect) => this.$store.commit('SET_ID', prospect._id)),
      // creates a GDPR Scan for the given prospect
      mergeMap((prospect) => {
        return apiClient.createScan({ prospect: prospect._id }).pipe(
          // gets the access token of the GDPR Scan
          map((res) => res.data.token),
        );
      }),
      // sets the access token of the GDPR Scan
      tap((token) => this.$store.commit('SET_TOKEN', token)),
      // polls the scanStatus of the GDPR Scan
      mergeMap((token) => {
        return timer(1, 3000).pipe(
          mergeMap(() => apiClient.getGDPRScanStatus(token).pipe(
            map((res) => res.data.scanStatus),
            map((scanStatus) => ({ scanStatus, token })),
          )),
          retry(),
        );
      }),
      // filters the scanStatus to be 'SCANNED_STARTPAGE'
      filter(({ scanStatus }) => scanStatus === 'SCANNED_STARTPAGE'),
      // gets the full GDPR Scan result
      mergeMap(({ token }) => {
        return apiClient.getGDPRScan(token).pipe(map((res) => res.data));
      }),
      // commits the retrieved data to the store
      tap((scan) => {
        this.$store.commit('SET_SCAN_RESULT', scan);
        this.$store.commit('TOGGLE_LOADING');
        this.$router.push('/result');
      }),
      // prepares to stop polling
      takeUntil(this.stopPolling),
      // stops polling
      tap(() => this.stopPolling.next()),
      // catches errors
      catchError((err) => {
        this.stopPolling.next();
        this.$store.commit('TOGGLE_LOADING');
        return of(err);
      }),
    ).subscribe();
  }
}
