




































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IScanResult, IResource, IPopulatedCookie } from '@/typings';
import MsCard from '@/components/MsCard.vue';

@Component({ components: { MsCard } })
export default class ScanResultTreatmentRecommendations extends Vue {
  @Prop()
  public scanResult!: IScanResult;

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public get showLongLastingCookiesTreatmentRecommendation(): boolean {
    return (
      this.$store.state.widgetConfig.treatmentRecommendationsTab
        .longLastingCookiesEnabled &&
      this.scanResult.populatedCookies.some(
        (cookie: IPopulatedCookie) =>
          new Date(cookie.expires).getTime() <
          this.addDays(
            new Date(this.scanResult.createdAt).getTime(),
            this.$store.state.widgetConfig.treatmentRecommendationsTab
              .longLastingCookiesDaysTrigger,
          ).getTime(),
      )
    );
  }

  public get showReduceResourcesTreatmentRecommendation(): boolean {
    return (
      this.$store.state.widgetConfig.treatmentRecommendationsTab
        .reduceResourcesEnabled &&
      this.scanResult.resources.length >=
        this.$store.state.widgetConfig.treatmentRecommendationsTab
          .reduceResourcesTrigger
    );
  }

  public get showPrivacyPolicyMentionsTreatmentRecommendation(): boolean {
    return (
      this.$store.state.widgetConfig.treatmentRecommendationsTab
        .privacyPolicyMentionsEnabled &&
      this.scanResult.scrapedPrivacyPolicyMentions.some(
        (privacyPolicyMention) =>
          privacyPolicyMention && privacyPolicyMention.rating < 0.7,
      )
    );
  }

  public get missingPrivacyPolicyMentions() {
    return this.scanResult.scrapedPrivacyPolicyMentions
      .filter(
        (privacyPolicyMention) =>
          privacyPolicyMention && privacyPolicyMention.rating < 0.7,
      )
      .map((privacyPolicyMention) => {
        const resource = this.scanResult.resources.find(
          (r) =>
            r.host &&
            r.host.thirdPartyProduct &&
            r.host.thirdPartyProduct._id ===
              privacyPolicyMention.thirdPartyProduct._id,
        );

        let externalLogoUrl = '';

        if (
          resource &&
          resource.host &&
          resource.host.thirdPartyProduct &&
          resource.host.thirdPartyProduct.umbrellaOrg &&
          resource.host.thirdPartyProduct.umbrellaOrg.externalLogoUrl
        ) {
          externalLogoUrl =
            resource.host.thirdPartyProduct.umbrellaOrg.externalLogoUrl;
        }
        return {
          ...privacyPolicyMention,
          externalLogoUrl,
        };
      });
  }

  private addDays(date: string | number, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
}
