


































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IScanResult, IPopulatedCookie } from '@/typings';
import { sortBy, take } from 'lodash';
import { format } from 'date-fns';

@Component
export default class ScanResultCookies extends Vue {
  @Prop()
  public scanResult!: IScanResult;

  public format = format;

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public get currentLocale() {
    return this.$i18n.locale;
  }

  public get items(): IPopulatedCookie[] {
    return sortBy(this.scanResult.populatedCookies, ['domain']);
  }

  public get truncatedItems() {
    return take(
      this.items,
      this.$store.state.widgetConfig.cookiesTab.maxVisibleEntries,
    );
  }

  public get truncDifferenceCount() {
    return this.items.length - this.truncatedItems.length;
  }
}
