var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ms-flex ms-flex-col"},[_c('div',{staticClass:"ms-flex"},_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.type,staticClass:"ms-text-center ms-p-3 ms-font-semibold ms-cursor-pointer ms-border-2 ms-rounded ms-rounded-b-none",class:{
        'ms-w-1/1': _vm.tabs.length === 1,
        'ms-w-1/2': _vm.tabs.length === 2,
        'ms-w-1/3': _vm.tabs.length === 3,
        'ms-border-b-0': _vm.activeTab === tab.type,
        'ms-text-gray-900 ms-bg-gray-100 ms-border-t-0 ms-border-r-0 ms-border-l-0 ms-border-b-2 hover:ms-text-black':
          _vm.activeTab !== tab.type,
      },on:{"click":function($event){_vm.activeTab = tab.type}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),0),_c('div',[(_vm.activeTab === 'resourcesTab')?_c('ScanResultResources',{attrs:{"scanResult":_vm.$store.state.scanResult}}):(_vm.activeTab === 'cookiesTab')?_c('ScanResultCookies',{attrs:{"scanResult":_vm.$store.state.scanResult}}):(_vm.activeTab === 'treatmentRecommendationsTab')?_c('ScanResultTreatmentRecommendations',{attrs:{"scanResult":_vm.$store.state.scanResult}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }