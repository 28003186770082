import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/HomePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/user-data',
    name: 'user-data',
    component: () => import('@/views/UserDataPage.vue'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/ResultPage.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
