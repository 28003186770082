





































import { Component, Vue } from 'vue-property-decorator';
import { sortBy } from 'lodash';
import {
  ScanResultResources,
  ScanResultCookies,
  ScanResultTreatmentRecommendations,
} from '@/components';

@Component({
  components: {
    ScanResultResources,
    ScanResultCookies,
    ScanResultTreatmentRecommendations,
  },
})
export default class ResultPage extends Vue {
  public activeTab: string = '';

  public tabs: any[] = [];

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public created() {
    if (
      !this.$store.state.firstName ||
      !this.$store.state.lastName ||
      !this.$store.state.email ||
      !this.$store.state._id ||
      !this.$store.state.scanResult ||
      !this.$store.state.scanResult.resources
    ) {
      this.$router.push('/user-data');
    }

    /**
     * Resources
     */
    if (this.$store.state.widgetConfig.resourcesTab.enabled) {
      this.tabs.push({
        ...this.$store.state.widgetConfig.resourcesTab,
        title: this.labels.resourcesTabTitle,
        type: 'resourcesTab',
      });
    }

    /**
     * Cookies
     */
    if (this.$store.state.widgetConfig.cookiesTab.enabled) {
      this.tabs.push({
        ...this.$store.state.widgetConfig.cookiesTab,
        title: this.labels.cookiesTabTitle,
        type: 'cookiesTab',
      });
    }

    /**
     * Treatment Recommendations
     */
    if (this.$store.state.widgetConfig.treatmentRecommendationsTab.enabled) {
      this.tabs.push({
        ...this.$store.state.widgetConfig.treatmentRecommendationsTab,
        title: this.labels.treatmentRecommendationsTabTitle,
        type: 'treatmentRecommendationsTab',
      });
    }

    this.tabs = sortBy(this.tabs, 'position');
    this.activeTab = this.tabs && this.tabs[0] && this.tabs[0].type;
  }
}
