import { render, staticRenderFns } from "./MsCard.vue?vue&type=template&id=5c4f86cb&scoped=true&"
import script from "./MsCard.vue?vue&type=script&lang=ts&"
export * from "./MsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4f86cb",
  null
  
)

export default component.exports