












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MsLoading extends Vue {
  @Prop({ default: '' })
  public message!: string;

  @Prop({ default: '' })
  public color!: string;
}
