








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { eventBus } from '@/libs/eventBus';

@Component
export default class MsModal extends Vue {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public body!: string;

  @Prop({ default: '' })
  public id!: string;

  public visible: boolean = false;

  public mounted(): void {
    eventBus.$on(`toggle-modal:${this.id}`, () => {
      this.toggle();
    });
  }

  public toggle(): void {
    this.visible = !this.visible;
  }
}
