import Vue from 'vue';
import Vuex from 'vuex';
import { IScanResult } from '@/typings/IScanResult';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    _id: '',
    apiKey: '',
    firstName: '',
    lastName: '',
    email: '',
    domain: '',
    phone: '',
    scanResult: {},
    token: '',
    widgetConfig: {
      color: '#0647a6',
      optIns: [],
      language: 'AUTO',
      domains: null,
      title: null,
      slug: null,
      createdAt: null,
      updatedAt: null,
      complyBranding: true,
      resourcesTab: {
        maxVisibleEntries: 99,
      },
      cookiesTab: {
        maxVisibleEntries: 99,
      },
      treatmentRecommendationsTab: {
        longLastingCookiesDaysTrigger: 90,
        reduceResourcesTrigger: 10,
      },
      localeBG: {},
      localeCS: {},
      localeDA: {},
      localeDE: {},
      localeEL: {},
      localeEN: {},
      localeES: {},
      localeET: {},
      localeFI: {},
      localeFR: {},
      localeGA: {},
      localeHE: {},
      localeHR: {},
      localeHU: {},
      localeIT: {},
      localeJA: {},
      localeLT: {},
      localeLV: {},
      localeMT: {},
      localeNL: {},
      localePL: {},
      localePT: {},
      localeRO: {},
      localeRU: {},
      localeSK: {},
      localeSL: {},
      localeSV: {},
      localeTR: {},
      localeZH: {},
    },
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
    SET_APIKEY(state, apiKey: string) {
      state.apiKey = apiKey;
    },
    SET_FIRSTNAME(state, firstName: string) {
      state.firstName = firstName;
    },
    SET_LASTNAME(state, lastName: string) {
      state.lastName = lastName;
    },
    SET_EMAIL(state, email: string) {
      state.email = email;
    },
    SET_PHONE(state, phone: string) {
      state.phone = phone;
    },
    SET_DOMAIN(state, domain: string) {
      state.domain = domain;
    },
    SET_WIDGET_CONFIG(state, widgetConfig: any) {
      state.widgetConfig = widgetConfig;
    },
    SET_ID(state, id: string) {
      state._id = id;
    },
    SET_SCAN_RESULT(state, scanResult: IScanResult) {
      state.scanResult = scanResult;
    },
    SET_TOKEN(state, token: string) {
      state.token = token;
    },
  },
  actions: {},
  modules: {},
});
