


























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IScanResult, IResource } from '@/typings';
import { sortBy, truncate, take } from 'lodash';

@Component
export default class ScanResultResources extends Vue {
  @Prop()
  public scanResult!: IScanResult;

  public get labels() {
    return this.$store.state.widgetConfig[
      `locale${this.$i18n.locale.toUpperCase()}`
    ];
  }

  public get items() {
    const items = this.scanResult.resources.map((resource: IResource) => {
      return {
        country: resource.country,
        umbrellaOrg:
          resource.host &&
          resource.host.thirdPartyProduct &&
          resource.host.thirdPartyProduct.umbrellaOrg,
        title:
          resource.host &&
          resource.host.thirdPartyProduct &&
          resource.host.thirdPartyProduct.title,
        url: truncate(resource.url, { length: 64 }),
      };
    });
    return sortBy(items, ['umbrellaOrg.title', 'title']);
  }

  public get truncatedItems() {
    return take(
      this.items,
      this.$store.state.widgetConfig.resourcesTab.maxVisibleEntries,
    );
  }

  public get truncDifferenceCount() {
    return this.items.length - this.truncatedItems.length;
  }
}
