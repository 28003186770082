<template>
  <div v-if="$store.state.widgetConfig && $store.state.widgetConfig.createdAt">
    <div class="ms-w-full ms-h-full">
      <router-view />
    </div>
    <div
      class="ms-text-right ms-mt-8"
      v-if="$store.state.widgetConfig.complyBranding"
    >
      <p>
        {{ $t('branding.we_use') }}
        <a
          class="ms-text-blue-200"
          href="https://comply-app.com"
          target="_blank"
          >Comply</a
        >
      </p>
    </div>
  </div>
</template>

<script>
  import { ApiClient } from '@/libs/ApiClient';

  export default {
    created() {
      const apiKey = document
        .querySelector('comply-widget-website-scan')
        .getAttribute('apiKey');

      this.$store.commit('SET_APIKEY', apiKey);

      const apiClient = new ApiClient({ apiKey });

      apiClient.getWidgetConfig().subscribe(({ data }) => {
        this.$store.commit('SET_WIDGET_CONFIG', data);

        if (
          this.$store.state.widgetConfig.language &&
          this.$store.state.widgetConfig.language !== 'AUTO'
        ) {
          this.$i18n.locale = this.$store.state.widgetConfig.language.toLowerCase();
        }
      });
    },
  };
</script>

<style lang="scss">
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  textarea {
    overflow: auto;
  }

  button {
    background-color: transparent;
    background-image: none;
    padding: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: #e2e8f0; /* 2 */
  }

  hr {
    border-top-width: 1px;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #a0aec0;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: #a0aec0;
  }

  input::placeholder,
  textarea::placeholder {
    color: #a0aec0;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }
</style>
