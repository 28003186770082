import 'document-register-element/build/document-register-element';
import '@/assets/css/tailwind.css';
import Vue from 'vue';
import App from './App.vue';
// @ts-ignore
import vueCustomElement from 'vue-custom-element';
import VueI18n from 'vue-i18n';
// @ts-ignore
import bg from './locales/bg.json';
// @ts-ignore
import cs from './locales/cs.json';
// @ts-ignore
import da from './locales/da.json';
// @ts-ignore
import de from './locales/de.json';
// @ts-ignore
import el from './locales/el.json';
// @ts-ignore
import en from './locales/en.json';
// @ts-ignore
import es from './locales/es.json';
// @ts-ignore
import et from './locales/et.json';
// @ts-ignore
import fi from './locales/fi.json';
// @ts-ignore
import fr from './locales/fr.json';
// @ts-ignore
import ga from './locales/ga.json';
// @ts-ignore
import he from './locales/he.json';
// @ts-ignore
import hr from './locales/hr.json';
// @ts-ignore
import hu from './locales/hu.json';
// @ts-ignore
import it from './locales/it.json';
// @ts-ignore
import ja from './locales/ja.json';
// @ts-ignore
import lt from './locales/lt.json';
// @ts-ignore
import lv from './locales/lv.json';
// @ts-ignore
import mt from './locales/mt.json';
// @ts-ignore
import nl from './locales/nl.json';
// @ts-ignore
import pl from './locales/pl.json';
// @ts-ignore
import pt from './locales/pt.json';
// @ts-ignore
import ro from './locales/ro.json';
// @ts-ignore
import ru from './locales/ru.json';
// @ts-ignore
import sk from './locales/sk.json';
// @ts-ignore
import sl from './locales/sl.json';
// @ts-ignore
import sv from './locales/sv.json';
// @ts-ignore
import tr from './locales/tr.json';
// @ts-ignore
import zh from './locales/zh.json';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(vueCustomElement);
// @ts-ignore
const browserLanguage = navigator.language || navigator.userLanguage;
const locale = browserLanguage.split('-')[0];

const i18n = new VueI18n({
  fallbackLocale: 'de',
  locale,
  messages: {
    bg,
    cs,
    da,
    de,
    el,
    en,
    es,
    et,
    fi,
    fr,
    ga,
    he,
    hr,
    hu,
    it,
    ja,
    lt,
    lv,
    mt,
    nl,
    pl,
    pt,
    ro,
    ru,
    sk,
    sl,
    sv,
    tr,
    zh,
  },
});

// @ts-ignore
App.i18n = i18n;

// @ts-ignore
App.store = store;
// @ts-ignore
App.router = router;

// @ts-ignore
Vue.customElement('comply-widget-website-scan', App);
