
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MsCard extends Vue {
  @Prop()
  public title!: string;

  @Prop()
  public description!: string;
}
