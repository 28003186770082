import { Observable, from } from 'rxjs';
import { retry } from 'rxjs/operators';
import axios, { AxiosResponse, AxiosInstance } from 'axios';

export interface ICreateProspectDTO {
  firstName: string;
  lastName: string;
  email: string;
  domain: string;
  phone?: string;
}

export class ApiClient {
  private apiKey!: string;
  private authenticatedRequest!: AxiosInstance;

  constructor({ apiKey }: { apiKey: string }) {
    const isProduction = process.env.NODE_ENV === 'production';
    this.apiKey = apiKey;
    this.authenticatedRequest = axios.create({
      baseURL: isProduction
        ? 'https://api.comply-app.com/websitescanwidgets'
        : 'http://0.0.0.0:1337/websitescanwidgets',
    });
  }

  public getWidgetConfig(): Observable<AxiosResponse> {
    return from<Promise<AxiosResponse>>(
      this.authenticatedRequest.get(`/config?apiKey=${this.apiKey}`),
    ).pipe(retry(5));
  }

  public createProspect(
    prospectData: ICreateProspectDTO,
  ): Observable<AxiosResponse> {
    return from<Promise<AxiosResponse>>(
      this.authenticatedRequest.post(
        `/create-prospect?apiKey=${this.apiKey}`,
        prospectData,
      ),
    ).pipe(retry(5));
  }
  public createScan({
    prospect,
  }: {
    prospect: string;
  }): Observable<AxiosResponse<{ token: string }>> {
    return from<Promise<AxiosResponse>>(
      this.authenticatedRequest.post(`/create-scan?apiKey=${this.apiKey}`, {
        prospect,
      }),
    ).pipe(retry(5));
  }

  public getGDPRScanStatus(
    token: string,
  ): Observable<AxiosResponse<{ scanStatus: string }>> {
    return from<Promise<AxiosResponse>>(
      this.authenticatedRequest.get('/scan-status', {
        params: {
          apiKey: this.apiKey,
          token,
        },
      }),
    );
  }

  public getGDPRScan(token: string): Observable<AxiosResponse> {
    return from<Promise<AxiosResponse>>(
      this.authenticatedRequest.get('/scan', {
        params: {
          apiKey: this.apiKey,
          token,
        },
      }),
    );
  }
}
